import type { Router } from 'vue-router'

export const MatomoOptions = (router: Router) => {
  const cookieDomain = __ENV_NAME__ === 'local' ? undefined : new URL(__BASE_URL_API__).host.replace('api', '*')

  return {
    host: "https://nabooedu.matomo.cloud/",
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
    router: router,
    enableLinkTracking: true, // Activer le suivi des liens
    trackInitialView: true, // Suivre la première vue automatiquement
    requireConsent: false, // Si vous gérez manuellement le consentement utilisateur
    enableHeartBeatTimer: true, // Garder la session active en envoyant des événements réguliers
    heartBeatTimerInterval: 15, // Intervalle (en secondes) pour les signaux d'activité
    debug: false, // Désactiver les logs en production,
    cookieDomain: cookieDomain
  }
}