<script setup lang='ts'>
import { EvaluationRange } from '@/assets/types/enums'
import { computed, type Ref, ref } from 'vue'
import type { SchoolClassroomScoreModuleLearningGrainDto } from '@/assets/DTO/school/school.classroom.response.dto'
import { default as POverlayPanel } from 'primevue/overlaypanel'

const props = defineProps<{
  grainEvalRange: EvaluationRange.Values | null
  grain?: SchoolClassroomScoreModuleLearningGrainDto
}>()

const tooltip: Ref<POverlayPanel | null> = ref(null)

const grainNameTooltip = computed(() => {
  if (props.grain) {
    return `${props.grain.learningGrainTitle} - Tentatives : ${props.grain.postQuizCountAttempt} - Dernier score : ${props.grain.postQuizScoreScaledHistory[props.grain.postQuizScoreScaledHistory.length - 1] * 100}% `
  }
  return null

})

const togglePopover = (event: Event) => {
  if (tooltip.value)
  tooltip.value.toggle(event)
}
</script>

<template>
  <div class='flex items-center justify-center border-1 border-bo-brand-secondary bg-white'
       style='width: 1.9rem; height: 1.9rem;'
        @mouseenter='(e) =>togglePopover(e)'
       @mouseleave='(e) =>togglePopover(e)'
  >
    <div
      v-if='grainEvalRange'
      class='h-full w-full flex justify-content-center align-items-center'
      :class='{

        "bg-green-300": grainEvalRange === EvaluationRange.Values.FORCE,
        "bg-yellow-300": grainEvalRange === EvaluationRange.Values.EFFORT_A_FAIRE,
        "bg-red-300": grainEvalRange === EvaluationRange.Values.DEFI
      }'
    >
      <i
        class='w-full h-full flex justify-content-center align-items-center'
        :class='{
          "pi pi-check": grainEvalRange === EvaluationRange.Values.FORCE,
          "text-3xl pr-1" : grainEvalRange === EvaluationRange.Values.EFFORT_A_FAIRE,
          "pi pi-times": grainEvalRange === EvaluationRange.Values.DEFI
        }'
      >
        <template v-if='grainEvalRange === EvaluationRange.Values.EFFORT_A_FAIRE'>
          ~
        </template>
      </i>
    </div>

  </div>
  <p-overlay-panel ref='tooltip'>
    <template v-if='grain'>
      <p class='text small font-bold'>{{ grain.learningGrainTitle}} :</p>
      <template
        v-if='grainEvalRange'
      >
        <p class='text small '>- Tentatives : {{ grain.postQuizCountAttempt}}</p>
        <p class='text small '>- Dernier score : {{ grain.postQuizScoreScaledHistory[grain.postQuizScoreScaledHistory.length - 1] * 100}}%</p>
      </template>
      <template v-else>
        <p class='text small'>
          Ce grain n'a pas encore été évalué
        </p>
      </template>

    </template>

  </p-overlay-panel>
</template>