<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import GroupDetailAttendance from '@/views/dashboard/blocks/details/GroupDetailAttendance.vue'
import GroupDetailSummary from '@/views/dashboard/blocks/details/GroupDetailSummary.vue'
import GroupDetailProgress from '@/views/dashboard/blocks/details/GroupDetailProgress.vue'
import GroupDetailMetrics from '@/views/dashboard/blocks/details/GroupDetailMetrics.vue'
import DashboardHeader from '@/views/dashboard/blocks/header/DashboardHeader.vue'
import { onBeforeRouteUpdate } from 'vue-router'

const Header = ref<HTMLElement | null>(null)

// Import Primevue services
const toast = useToast()

// Import stores
const { fetchSchoolClassroomById, resetClassroom } = useSchoolClassroomStore()

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
}>()

onMounted(async () => {
  try {
    await fetchSchoolClassroomById(props.schoolId, props.groupId)
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: 'Impossible de charger les détails du groupe',
      life: 5000
    })
  }
})

onUnmounted(() => {
  resetClassroom()
})

onBeforeRouteUpdate(async (to, from, next) => {
  if (to.params.groupId !== from.params.groupId) {
    await fetchSchoolClassroomById(Number(to.params.schoolId), Number(to.params.groupId))
  }
  next()
})
</script>

<template>
  <group-detail-metrics />

  <!-- Progression du groupe -->
  <group-detail-progress :schoolId="props.schoolId" :groupId="props.groupId" />

  <!-- Assiduité des élèves -->
  <group-detail-attendance :schoolId="props.schoolId" :groupId="props.groupId" />

</template>
