<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import GroupProgressDiagnostic from '@/views/dashboard/blocks/progress/GroupProgressDiagnostic.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { LearningCourseResponseDto } from '@/assets/DTO/learning/learningCourse.response.dto'
import GroupProgressTask from '@/views/dashboard/blocks/progress/GroupProgressTask.vue'

const { fetchSchoolClassroomById, resetScoreDiagnostic, resetScoreModule } =
  useSchoolClassroomStore()
const { classroom } = storeToRefs(useSchoolClassroomStore())
const { fetchCourse } = useLearningCoursesStore()

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
  courseId: number
}>()

const Header = ref<HTMLElement | null>(null)
const learningCourse = ref<LearningCourseResponseDto>({} as LearningCourseResponseDto)

onMounted(async () => {
  try {
    await Promise.all([fetchSchoolClassroomById(props.schoolId, props.groupId)])
    learningCourse.value = await fetchCourse(props.courseId)
  } catch (error) {
    console.error(error)
  }
})

onUnmounted(async () => {
  resetScoreDiagnostic()
  resetScoreModule()
})
</script>

<template>


    <group-progress-diagnostic
      :schoolId="props.schoolId"
      :groupId="props.groupId"
      :courseId="props.courseId"
    />

    <group-progress-task
      v-for="(module, i) in learningCourse.activeModules"
      :key="module.id"
      :schoolId="props.schoolId"
      :groupId="props.groupId"
      :courseId="props.courseId"
      :taskId="module.task.id"
      :moduleId="module.id"
      :title="`Thématique ${i + 1} : ${module.title}`"
    />
</template>
