<script setup lang='ts'>
import DashboardHeader from '@/views/dashboard/blocks/header/DashboardHeader.vue'
import { useRoute, useRouter } from 'vue-router'
import { nextTick, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school'
import { useProfileStore } from '@/stores/profile'

// get props from route
const route = useRoute()
const router = useRouter()
const schoolId = Number(route.params.schoolId)
const groupId = Number(route.params.groupId)
const { isTeacher } = storeToRefs(useAuthStore())
const { schoolList } = storeToRefs(useSchoolStore())
const { me } = storeToRefs(useProfileStore())

const loading = ref(true)

onMounted(() => {

  // If the user is a teacher, redirect to the default classroom
  if (isTeacher.value) {
    const defaultClassroom = getDefaultClassroom()
    router.push({
      name: 'dashboard-group',
      params: { schoolId: schoolList.value[0].id, groupId: defaultClassroom.id }
    })
  }


  nextTick(() => {
    loading.value = false
  })
})

const getDefaultClassroom = () => {
  // Default classroom is the first classroom of the first school
  let defaultClassroom = schoolList.value[0].classrooms[0]

  if (schoolList.value.length > 0 ) {
    // Search for the first classroom where the user is a monitor
    for (const school of schoolList.value) {
      const classroom = school.classrooms.find((classroom) => {
        const classroomMonitors = classroom.monitors.map((monitor) => monitor.identifier);
        if (me.value && me.value.identifier)
          return classroomMonitors.includes(me.value.identifier);
      });

      if (classroom) {
        defaultClassroom = classroom;
        break; // Once the classroom is found, exit the loop
      }
    }
  }

  return defaultClassroom;
};

</script>


<template>
  <dashboard-header title='Tableau de bord' :school-id='schoolId' :group-id='groupId' />
  <div
    class="col-12 p-5 pt-2 bg-bo-brand-tertiary flex-1 overflow-y-auto gap-5 flex flex-column"
  >
    <router-view v-if='!loading' />
  </div>
</template>
