import { useNabooContentStore } from '@/stores/nabooContent'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import NabooContentDiagnosticDialog from '@/views/nabooContent/blocks/NabooContentDiagnosticDialog.vue'
import { useDialog } from 'primevue/usedialog'
import { NabooContentDiagnosticDto } from '@/assets/DTO/nabooContent/nabooContent.dto'
import { useRouter } from 'vue-router'
import { props } from '@/components/templates/DefaultTemplateProps'

export function useNabooContent() {
  // SERVICES
  const toast = useToast()
  const dialog = useDialog()
  const router = useRouter()
  // STORES
  const { fetchNabooContent } = useNabooContentStore()
  const { listAxis } = storeToRefs(useNabooContentStore())

  onMounted(async () => {
    try {
      await fetchNabooContent()
    } catch (error) {
      if (error instanceof Error) {
        toast.add({ severity: 'error', summary: 'Erreur', detail: error.message })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Une erreur est survenue, merci de contacter le support'
        })
      }
    }
  })

  const openDiagnosticDialog = ( courseId: number) => {
    const axis = listAxis.value.find((axis) => axis.learningCourses.some((course) => course.id === courseId))
    if (!axis) return
    const input = axis.learningCourses.find((course) => course.id === courseId)?.diagnostic

    if (input) {
      dialog.open(NabooContentDiagnosticDialog, {
        props: {
          header: `Parcours - ${input.learningCourseTitle}`,
          modal: true,
          dismissableMask: true,
          draggable: false,
          style: 'width: 50vw',
          breakpoints: {
            '960px': '50vw',
            '640px': '95vw'
          }
        },
        data: {
          diagnostic: input,
          subtitle: input.title
        }
      })
    }
  }

  const openModuleContent = async (moduleId: number, courseId: number) => {
    window.open(`/contenus-naboo/parcours/${courseId}/modules/${moduleId}`, '_blank')
    return
  }

  return {
    openDiagnosticDialog,
    openModuleContent
  }
}