<script lang='ts' setup>
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'

import type { LearningCourseResponseDto } from '@/assets/DTO/learning/learningCourse.response.dto'

const props = defineProps<{
  learningCourse: LearningCourseResponseDto
  schoolId: number
  groupId: number
}>()
</script>

<template>
  <div
    class="col-3  flex flex-column gap-3 align-items-start  border-round-2xl bg-white p-5 border-1 border-bo-brand-secondary"
  >
    <div class="grid gap-3">
      <h4 class='heading large font-bold col-12'>{{ learningCourse.title }}</h4>
      <p-tag class="bg-orange-400 text-orange-900 uppercase py-1 align-self-start" rounded>
        Exploration {{ learningCourse.name }}
      </p-tag>
    </div>

    <div
      class='grid grid-nogutter gap-3 py-3 w-full'
    >
      <div v-for='module in learningCourse.activeModules' :key='`Module_${module.id}`' class='col-12'>
        • {{ module.title }}
      </div>
    </div>

    <div
      class='flex flex-column w-full align-items-center mt-auto'
    >
      <router-link
        class="bo-active-color text-right no-underline"
        :to="{
            name: 'dashboard-group-progress',
            params: { schoolId: props.schoolId, groupId: props.groupId, courseId: learningCourse.id }
          }"
      >
        <p-button outlined severity="primary" label="Voir les détails et résultats"></p-button>
      </router-link>

      <router-link
        class="bo-active-color text-right no-underline"
        :to="{
            name: 'naboo-content-list',
            params: { schoolId: props.schoolId, groupId: props.groupId, courseId: learningCourse.id }
          }"
      >
        <p-button text severity="primary" label="Voir les contenus"></p-button>
      </router-link>
    </div>

  </div>
</template>