<script setup lang="ts">
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

import { default as PToast } from 'primevue/toast'
import { default as PConfirmDialog } from 'primevue/confirmdialog'
import { default as PDynamicDialog } from 'primevue/dynamicdialog'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useMagicKeys } from '@vueuse/core'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import VUnauthorized from '@/views/errors/VUnauthorized.vue'
import { useSchoolStore } from '@/stores/school'

const { initLogin } = useAuthStore()
const { isAuthenticated, isAllowedToLogIn } = storeToRefs(useAuthStore())
const { fetchSchools } = useSchoolStore()

// Cheats to get current version of the app
const keys = useMagicKeys()
const versionKeys = keys['Ctrl+Alt+w']
watch(versionKeys, async (pressed) => {
  if (pressed) alert(`Version : ${__APP_NAME__}@${__APP_VERSION__}`)
})

// Loading
const loading = ref(false)

onMounted(async () => {
  await handleLoginProcess()
})

onBeforeMount(() => {
  loading.value = true
})

async function handleLoginProcess() {
  const stopWatching = watch(isAuthenticated, async (newVal) => {
    if (newVal) {
      try {
        await initLogin()
      } catch (error) {
        // Do nothing
      } finally {
        stopWatching()
        await fetchSchools()
        loading.value = false
      }
    }
  })
}
</script>

<template>
  <default-template :with-sidebar="$route.meta.withSidebar as boolean">
    <div v-if="loading" class="flex justify-center items-center h-screen w-screen">
      <p-progress-spinner />
    </div>
    <v-unauthorized v-else-if="!isAllowedToLogIn" />
    <RouterView v-else />
    <p-toast />
    <p-confirm-dialog></p-confirm-dialog>
    <p-dynamic-dialog />
  </default-template>
</template>
