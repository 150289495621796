<script setup lang="ts">
import { useSchoolStore } from '@/stores/school'
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import GroupListSchool from '@/views/dashboard/blocks/list/GroupListSchool.vue'

const { fetchSchools } = useSchoolStore()
const { schoolList } = storeToRefs(useSchoolStore())

const Header = ref<HTMLElement | null>(null)

onMounted(async () => {
  await fetchSchools()
})
</script>

<template>

    <group-list-school v-for="school in schoolList" :key="`school_${school.id}`" :school="school" />
</template>
