<script setup lang="ts">
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import { computed, onMounted, type Ref, ref } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import type { IUser } from '@/assets/types/UserTypes'
import ThemeTableScore from '@/views/dashboard/blocks/progress/ThemeTableScore.vue'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PInputText } from 'primevue/inputtext'
import { default as PInputGroup } from 'primevue/inputgroup'
import PChart from 'primevue/chart'
import { FilterMatchMode, type FilterMatchModeOptions } from 'primevue/api'
import ModuleTableTaskStatus from '@/views/dashboard/blocks/progress/ModuleTableTaskStatus.vue'
import type { ChartOptions } from 'chart.js'
import { EvaluationRange } from '@/assets/types/enums'
import { useToast } from 'primevue/usetoast'
import type { SchoolClassroomScoreModuleLearningGrainDto } from '@/assets/DTO/school/school.classroom.response.dto'
import { useNabooContent } from '@/assets/composables/useNabooContent'
import { useProfileStore } from '@/stores/profile'

// Import Primevue services
const toast = useToast()
const { openModuleContent } = useNabooContent()

const props = defineProps<{
  title: string
  courseId: number
  schoolId: number
  groupId: number
  moduleId: number
  taskId: number
}>()

const { getFullName } = storeToRefs(useProfileStore())
const { fetchSchoolClassroomScoreModule } = useSchoolClassroomStore()
const { scoreModule } = storeToRefs(useSchoolClassroomStore())

onMounted(async () => {
  try {
    await fetchSchoolClassroomScoreModule(props.schoolId, props.groupId, props.taskId)
    filters.value.teacherFullname.value = teacherOptn.value?.value ?? null
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: 'Impossible de charger les détails de la thématique',
      life: 5000
    })
  }
})

const dataSource = computed(() => {
  return scoreModule.value.data[props.taskId]?.map((student) => {
    return {
      ...student,
      teacherFullname: student.teacherFirstname
        ? `${student.teacherFirstname} ${student.teacherLastname}`
        : undefined
    }
  })
})

const teachersOptions = computed(() => {
  const teachers = [
    ...new Set(
      dataSource.value
        ?.filter((student) => student.teacherFullname)
        ?.map((student) => student.teacherFullname)
    )
  ]?.map((teacher) => {
    return {
      label: teacher,
      value: teacher
    }
  })

  return teachers ? [{ label: 'Tous les enseignants', value: null }, ...teachers] : []
})

const teacherOptn = computed(() => {
  return teachersOptions.value.find((teacher) => teacher.value === getFullName.value)
})

/*
 *
 *
 * TABLE DATA & filters
 *
 * */
const filters: Ref<{
  global: { value: string | null; matchMode: string }
  firstname: { value: string | null; matchMode: string }
  lastname: { value: string | null; matchMode: string }
  teacherFullname: { value: string | null; matchMode: string }
}> = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  teacherFullname: { value: teacherOptn.value?.value ?? null, matchMode: FilterMatchMode.EQUALS }
})

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      color: '#000',
      font: {
        size: 12,
        weight: 'bold'
      },
      formatter: (value) => value // Affiche la valeur
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        callback: function (value: any, index: number, ticks: any) {
          const label = this.getLabelForValue(value)
          return label.length > 20 ? label.substring(0, 17) + '...' : label
        }
      },
      grid: {
        display: false
      },
      barPercentage: 0.8 // Ajuste l'espacement entre les groupes
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        stepSize: 1 // Ajuste les intervalles sur l'axe Y pour une meilleure lisibilité
      },
      grid: {
        color: '#E5E5E5'
      }
    }
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 30,
      bottom: 10
    }
  }
}

type DataChar = {
  labels: string[]
  datasets: {
    label: string
    backgroundColor: string[]
    data: number[]
  }[]
}

const transformDataForChart = computed((): DataChar => {
  const data = scoreModule.value.data[props.taskId] ?? []
  const result = {
    defi: 0,
    effortsAFaire: 0,
    force: 0,
    moduleEnCours: 0
  }

  data.forEach((student) => {
    if (student.moduleEvalRangeName === EvaluationRange.Values.DEFI) {
      result.defi += 1
    } else if (student.moduleEvalRangeName === EvaluationRange.Values.EFFORT_A_FAIRE) {
      result.effortsAFaire += 1
    } else if (student.moduleEvalRangeName === EvaluationRange.Values.FORCE) {
      result.force += 1
    } else {
      result.moduleEnCours += 1
    }
  })

  return {
    labels: ['Non commencé', 'Défi', 'Efforts à faire', 'Force'],
    datasets: [
      {
        label: 'Module en cours',
        backgroundColor: ['#999', '#FF6F61', '#FBA86F', '#32CD32'],
        data: [result.moduleEnCours, result.defi, result.effortsAFaire, result.force]
      }
    ]
  }
})



</script>

<template>
  <dashboard-base-block :title="title" accordion>
    <a class='bo-active-color cursor-pointer' @click='openModuleContent(moduleId, courseId)'>Découvrir les contenus du module <i class='pi pi-external-link' /></a>
    <div
      class="border-1 border-bo-brand-secondary mt-4 p-4 flex flex-column gap-4 border-round-2xl col-12"
    >
      <h4 class="heading medium text-800">Répartition des élèves par intervalles de scores</h4>
      <div class="col-6 max-h-15rem">
        <p-chart
          class="w-full h-full"
          type="bar"
          :data="transformDataForChart"
          :options="chartOptions"
        />
      </div>

      <h4 class="heading medium text-800">Détails par élève</h4>

      <!-- Légende      -->
      <div
        class='bg-bo-brand-tertiary p-3 border-round-2xl grid col-12 gap-2 align-items-center'
      >
        <h5 class='brand-heading small text-800 col-12'>Légende</h5>
        <div class='flex gap-4'>
          <div class='flex gap-2 align-items-center'>
            <module-table-task-status :grain-eval-range='null' /> <p>Session non réalisée par l'élève</p>
          </div>
          <div class='flex gap-2 align-items-center'>
            <module-table-task-status :grain-eval-range='EvaluationRange.Values.FORCE' /> <p><span class='font-bold'>Force : </span>score de l'élève > 70%</p>
          </div>
          <div class='flex gap-2 align-items-center'>
            <module-table-task-status :grain-eval-range='EvaluationRange.Values.EFFORT_A_FAIRE' /> <p><span class='font-bold'>Efforts à faire : </span>score de l'élève entre 40% et 70%</p>
          </div>
          <div class='flex gap-2 align-items-center'>
            <module-table-task-status :grain-eval-range='EvaluationRange.Values.DEFI' /> <p><span class='font-bold'>Défi : </span>{{`score de l'élève < 40%`}}</p>
          </div>

        </div>
      </div>
      <p-data-table
        :value="dataSource"
        dataKey="id"
        class="text-sm mt-4 overflow-x-auto"
        size="small"
        :loading="scoreModule.loading"
        v-model:filters="filters"
        removableSort
        scrollable
        sortMode="multiple"
        paginator
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        :globalFilterFields="['firstname', 'lastname']"
      >
        <!-- Header -->
        <template #header>
          <div class="col-12 grid flex justify-content-start align-items-center max-h-min">
            <p-input-group class="col-2 max-h-min h-4rem">
              <p-input-text
                autocomplete="off"
                data-lpignore="true"
                data-form-type="other"
                class="text-base"
                placeholder="Chercher un élève"
                v-model="filters['global'].value"
                size="small"
              />
            </p-input-group>
            <p-input-group class="col-3 max-h-min h-4rem">
              <p-dropdown
                v-model="filters['teacherFullname'].value"
                :options="teachersOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Filtrer par enseignant référent"
                size="small"
              />
            </p-input-group>
          </div>
        </template>
        <template #empty> Aucune donnée trouvée.</template>
        <template #loading> Chargement des données.</template>

        <!-- Body -->
        <p-column field="firstname" frozen :sortable="true" header="Prénom" class="w-12rem">
          <template #body="{ data }">
            <p class="Center">{{ (data as IUser).firstname }}</p>
          </template>
        </p-column>

        <p-column field="lastname" frozen :sortable="true" header="Nom" class="w-12rem">
          <template #body="{ data }">
            <p class="Center">{{ (data as IUser).lastname }}</p>
          </template>
        </p-column>

        <p-column field="teacherFullname" :sortable="true" header="Enseignant" class="w-12rem">
          <template #body="{ data }">
            <p class="Center">
              {{ data.teacherFullname ? data.teacherFullname : '-' }}
            </p>
          </template>
        </p-column>

        <p-column
          field="diagScorePercentRounded"
          :sortable="true"
          header="Score diagnostic"
          class="w-12rem"
        >
          <template #body="{ data }">
            <theme-table-score
              :score="data.diagScorePercentRounded"
              :eval-range="data.diagEvalRangeName"
            />
          </template>
        </p-column>

        <p-column
          field='learningGrains'
          header='Sessions'
          class="w-12rem"
        >
          <template #body="{ data }">
            <div class='flex gap-2'>
              <module-table-task-status
                v-for='grain in data.learningGrains'
                :grain='grain'
                :key='grain.learningGrainId'
                :grain-eval-range='grain.postQuizEvalRangeName'
              />
            </div>
          </template>

        </p-column>

        <p-column
          field="moduleScorePercentRounded"
          :sortable="true"
          header="Score module"
          class="w-12rem"
        >
          <template #body="{ data }">

            <p class='text-800 font-italic' v-if='data.learningGrains.filter((grain: SchoolClassroomScoreModuleLearningGrainDto) => grain.postQuizEvalRangeName === null).length > 0'> En cours</p>
            <theme-table-score
              v-else
              :score="data.moduleScorePercentRounded"
              :eval-range="data.moduleEvalRangeName"
              :progress="
                data.diagnosticModuleVariation > 0
                  ? '+'
                  : data.diagnosticModuleVariation < 0
                    ? '-'
                    : '='
              "
            />
          </template>
        </p-column>
      </p-data-table>
    </div>
  </dashboard-base-block>
</template>
