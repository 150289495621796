import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/core/'
import type { IUser } from '@/assets/types/UserTypes'
import { useAuth0 } from '@auth0/auth0-vue'
import { Gender } from '@/assets/types/UserTypes'

interface IPreferences {
  menuOpened: boolean
}

export const useProfileStore = defineStore('Profile', () => {
  // STATE
  const me: RemovableRef<IUser | undefined> = ref(useLocalStorage('me', undefined))
  const preferences: RemovableRef<IPreferences> = useLocalStorage('preferences', {
    menuOpened: true
  })
  const { idTokenClaims } = useAuth0()

  // ACTIONS
  async function getMe() {
    try {
      const res = await nabooApi.getMe()
      me.value = res.data
    } catch (error: any) {
      me.value = undefined
    }
    return Promise.resolve()
  }

  function toggleMenuOpen() {
    preferences.value.menuOpened = !preferences.value.menuOpened
  }

  // GETTERS (COMPUTED)
  const getFirstNameInitial = computed(() => {
    return idTokenClaims.value?.given_name?.charAt(0) ?? 'A'
  })

  const getFullName = computed(() => {
    return idTokenClaims.value?.name
  })

  const getPicture = computed(() => {
    return idTokenClaims.value?.picture
  })

  const isMenuOpened = computed(() => preferences.value.menuOpened)

  return { getMe, toggleMenuOpen, me, getFirstNameInitial, getFullName, getPicture, isMenuOpened }
})
