<script setup lang="ts">
import { useLearningCoursesStore } from '@/stores/learningCourses'
import { computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import GroupDetailCourse from '@/views/dashboard/blocks/details/GroupDetailCourse.vue'

const { fetchAllCourses } = useLearningCoursesStore()
const { learningCourses } = storeToRefs(useLearningCoursesStore())

// Inherit from router
const props = defineProps<{
  schoolId: number
  groupId: number
}>()

onMounted(async () => {
  await fetchAllCourses()
})

/**
 * Get active learning courses
 */
const activeLearningCourses = computed(() => {
  return learningCourses.value.filter((course) => course.isActive())
})
</script>

<template>
  <div class="flex flex-column grid col-12">
    <dashboard-base-block title="Progression du groupe" :accordion='false' >
      <div
        class='grid gap-3 p-3 w-full'
      >
        <group-detail-course
          v-for="course in activeLearningCourses"
          :key="`course_${course.id}`"
          :learningCourse="course"
          :schoolId="props.schoolId"
          :groupId="props.groupId"
        />
      </div>

    </dashboard-base-block>
  </div>
</template>
